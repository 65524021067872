/* eslint-disable no-undef */
$(document).ready(function () {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 150) {
            $('#myBtn').addClass('myBtn');
        } else {
            $('#myBtn').removeClass('myBtn');
        }
    });

    $('#myBtn').click(function() {
        $('html').css('scroll-behavior', 'auto');
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });
});

window.onload = function() {
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }
}

//from left to right animation
$(document).ready(function () {
    var $elements = $(`
        .technology, #smart-camera-solution .smart-camera-slide,
        #partner-page .plan-matrix, .features .item, .service .option, .solution-content .solution-box
    `);
    $elements.each(function() {
        $(this).addClass('hidden-left');
        var elementTop = $(this).offset().top;

        if ($(window).height() > elementTop) {
            $(this).addClass('fade-left');
        }
    });

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();

        $elements.each(function() {
            var elementTop = $(this).offset().top;

            if (scroll + windowHeight > elementTop) {
                $(this).addClass('fade-left');
            }
        });
    });
});

// from botton to top animation
$(document).ready(function () {
    var $elements = $(`
            .advantage, .major, 
            #sale-pusher .sale-pusher .fade-botton, #sale-pusher .sale-pusher-box, #sale-pusher .usage-example-box,
            #smart-camera-solution .col-problem, #smart-camera-solution .household-box, #smart-camera-solution .video,
            #partner-page .partner-problem-box, .workflow .workflow-img,
            #homepage .question, #homepage .banner-content, #homepage .customer .first-content
        `);
    $elements.each(function() {
        $(this).addClass('hidden-botton');
        var elementTop = $(this).offset().top;

        if ($(window).height() > elementTop) {
            $(this).addClass('fade-botton');
        }
    });

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();

        $elements.each(function() {
            var elementTop = $(this).offset().top;

            if (scroll + windowHeight + 200 > elementTop) {
                $(this).addClass('fade-botton');
            }
        });
    });
});

// fade on load
document.addEventListener("DOMContentLoaded", function () {
    const elements = document.querySelectorAll("#smart-camera-solution .banner-box");
    elements.forEach(function (element, index) {
        gsap.fromTo(
            element,
            { opacity: 0, x: -200 },
            { opacity: 1, x: 0, duration: 1, delay: index * 0.2 }
        );
    });
});

// banner particles
var particlesContainer = document.getElementById("particles-js");
if (particlesContainer) {
    particlesJS("particles-js", {
        particles: {
            number: {
                value: 8,
                density: {
                    enable: true,
                    value_area: 800
                }
            },
            color: {
                value: "#FFFFFF",
            },
            shape: {
                type: "image",
                image: {
                    src: "/frontend/assets/images/home/circle-gradient.png",
                    width: 100,
                    height: 100
                }
            },
            opacity: {
                value: 1,
                random: true,
                anim: {
                    enable: false,
                    speed: 10,
                    opacity_min: 1,
                    sync: false
                }
            },
            size: {
                value: 60,
                random: true,
                anim: {
                    enable: false,
                    speed: 40,
                    size_min: 10,
                    sync: false
                }
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: "#FFFFFF",
                opacity: 0,
                width: 0
            },
            move: {
                enable: true,
                speed: 2,
                direction: "none",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        },
        interactivity: {
            events: {
                onhover: {
                    enable: true,
                    mode: "repulse"
                },
                onclick: {
                    enable: true,
                    mode: "push"
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 400,
                    line_linked: {
                        opacity: 1
                    }
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3
                },
                repulse: {
                    distance: 20,
                    duration: 1
                },
                push: {
                    particles_nb: 2
                },
                remove: {
                    particles_nb: 2
                }
            }
        },
        retina_detect: true
    });
}